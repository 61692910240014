body, html, #root {
    height: 100%;
    padding: 0px;
    overflow: hidden; /* Prevent scrollbars from appearing */
}

.text-light-grey {
    color: #959caf; /* You can adjust the grey shade as needed */
}

.tech-highlight {
    color: #FFFFFF; /* White color for technical words */
}


.about-cards-container {
    display: flex;
    flex-wrap: wrap;
}



.spotlight {
    position: fixed;
    top: 0;
    left: 0;
    width: 1400px;
    height: 1400px;
    background: radial-gradient(circle, rgba(0, 73, 231, 0.135) 0%, rgba(255,255,255,0) 50%);
    pointer-events: none;
    transform: translate(-50%, -50%);
    border-radius: 80%;
    z-index: 100; /* Ensure it stays above all content */
}

.img-fluid.double-size {
    width: 50%;  /* Adjust according to your preference */
    height: auto; /* Maintain aspect ratio */
}

.mt-6 {
    margin-top: 15rem; 
}

.mt-7 {
    margin-top: 7rem; 
}
.mt-8 {
    margin-top: 6rem; 
}

.mb-6 {
    margin-bottom: 8rem; 
}
.mt-9{
    margin-top: 10rem;
}

.mb-8 {
    margin-bottom: 6rem; 
}

.icon-link {
    color: white; /* Initial color */
    transition: color 0.3s ease; /* Smooth transition */
}
.icon-link.cv-download {
    color: #959caf; /* LinkedIn color */
}
.icon-link.cv-download:hover {
    color: #ffffff; /* LinkedIn color */
}
.icon-link.linkedin {
    color: #959caf; /* LinkedIn color */
}
.icon-link.linkedin:hover {
    color: #ffffff; /* LinkedIn color */
}
.icon-link.fiverr {
    color: #959caf; /* Fiverr color */
}
.icon-link.fiverr:hover {
    color: #ffffff; /* Fiverr color */
}
.icon-link.upwork {
    color: #959caf; /* Upwork color */
}
.icon-link.upwork:hover {
    color: #ffffff; /* Upwork color */
}
.icon-link.github {
    color: #959caf; /* GitHub color */
}
.icon-link.github:hover {
    color: #ffffff; /* GitHub color */
}
.icon-link.phone {
    color: #959caf; /* Upwork color */
}
.icon-link.phone:hover {
    color: #ffffff; /* Upwork color */
}
.icon-link.email {
    color: #959caf; /* GitHub color */
}

.icon-link.email:hover {
    color: #ffffff; /* GitHub color */
}


#carouselExampleRide {
    min-height: 275px;
}

@media (max-width: 576px) {
    #carouselExampleRide {
        min-height: 180px;
    }
}