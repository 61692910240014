

// Importing a Google Font
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

$primary: rgb(5, 19, 43);
$text-primary: red;

$font-family-sans-serif: 'Roboto', sans-serif;  // Using Roboto as an example
$header-font-family: 'Montserrat', sans-serif;


@import "../node_modules/bootstrap/scss/bootstrap";