.custom-badge {
    background-color: #014c678c; /* Custom background color */
    color: #00ccff; /* Custom text color */
    margin-left: 1px;
    font-weight: lighter;
}

.custom-text-secondary{
    color:  #959caf; /* Custom text color */
    font-weight: lighter;
    font-size: 15px;
}

.custom-text-secondary-2{
    color:  rgb(149, 156, 175); /* Custom text color */
    font-weight: lighter;
    font-size: 13.5px;
}

.custom-text-primary{
    color: #ffffff; /* Custom text color */
    font-weight: lighter;
}

.custom-text-primary-1{
    color: #ffffff; /* Custom text color */
    font-weight: lighter;
}

.custom-text-primary-2{
    color: #00d0ff; /* Custom text color */
    font-weight: lighter;
}

.contributions-link:hover h6 {
    color: #00d0ff; /* Replace with your desired hover color */
}
.contributions-link h6 {
    transition: color 0.5s ease; /* Adjust the duration and timing function as needed */
}